import { GlobalService } from './global.service';
import { Injectable, OnInit } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { share, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  url: string = environment.apiBaseUrl;
  headers: HttpHeaders = new HttpHeaders(); //.set('Content-Type', 'application/json');
  getOptions(): object {
    return { observe: 'body' };
  }

  constructor(
    private http: HttpClient,
    private global: GlobalService,
    private _router: Router
  ) {}

  get<T>(endpoint: string, params?: any, heads?: any) {
    if (this.global.user != null) {
      if (this.global.user.token != null && this.global.user.token != '') {
        this.headers = this.headers.set(
          'Authorization',
          'Bearer ' + this.global.user.token
        );
      }
    }
    let reqOpts: any = { headers: this.headers };
    if (heads) {
      for (let k in heads) {
        reqOpts.headers = reqOpts.headers.set(k, heads[k]);
      }
    }

    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    let result = this.http
      .get<T>(this.url + '/' + endpoint, reqOpts)
      .pipe(share());

    result.subscribe(
      (resp) => {},
      (error) => {
        if (error.status == '401') {
          this._router.navigate(['/login']);
        }
      }
    );

    return result;
  }

  post<T>(endpoint: string, body: any, reqOpts?: any, heads?: any) {
    if (this.global.user != null) {
      if (this.global.user.token != null && this.global.user.token != '') {
        this.headers = this.headers.set(
          'Authorization',
          'Bearer ' + this.global.user.token
        );
      }
    }

    reqOpts = { headers: this.headers };
    if (heads) {
      for (let k in heads) {
        reqOpts.headers = reqOpts.headers.set(k, heads[k]);
      }
    }

    let result = this.http
      .post<T>(this.url + '/' + endpoint, body, reqOpts)
      .pipe(share());

    result.subscribe(
      (resp) => {},
      (error) => {
        if (error.status == '401') {
          this._router.navigate(['/login']);
        }
      }
    );

    return result;
  }
}
