import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnackbarService } from '../services/global/snackbar.service';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private snackbarService: SnackbarService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const logFormat = 'background: maroon; color: white';
    return next.handle(req).pipe(
      tap(
        (event) => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case HttpError.UnknownError:
                console.error('%c Unknown Error 0', logFormat);
                this.snackbarService.RedAlert('Cannot reach server', 'Dismiss');
                break;
              case HttpError.BadRequest:
                console.error('%c Bad Request 400', logFormat);
                this.snackbarService.RedAlert('Bad Request', 'Dismiss');
                break;
              case HttpError.Unauthorized:
                console.error('%c Unauthorized 401', logFormat);
                this.snackbarService.RedAlert('Unauthorized', 'Dismiss');
                break;
              case HttpError.NotFound:
                console.error('%c Not Found 404', logFormat);
                this.snackbarService.RedAlert('Not Found', 'Dismiss');
                break;
              case HttpError.TimeOut:
                console.error('%c TimeOut 408', logFormat);
                this.snackbarService.RedAlert('TimeOut', 'Dismiss');
                break;
              case HttpError.Forbidden:
                console.error('%c Forbidden 403', logFormat);
                this.snackbarService.RedAlert('Forbidden', 'Dismiss');
                break;
              case HttpError.InternalServerError:
                console.error('%c big bad 500', logFormat);
                this.snackbarService.RedAlert('Cannot reach server', 'Dismiss');
                break;
            }
          }
        }
      )
    );
  }
}
export class HttpError {
  static UnknownError = 0;
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static TimeOut = 408;
  static Conflict = 409;
  static InternalServerError = 500;
}
