import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, pipe, throwError, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { GlobalService } from './global.service';
import { User, Result } from '../interfaces';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private message: string;

  constructor(
    private _router: Router,
    public _global: GlobalService,
    private api: APIService
  ) {}

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    localStorage.clear();
    let blankuser: User = {
      token: '',
      firstName: '',
      lastName: '',
      parentId: '',
      physicalAddress: '',
      profilePicture: '',
      isAdmin: false,
      id: '',
    };
    this._global.user = blankuser;
    this._global.userCache.next(blankuser);
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return (
      localStorage.getItem('token') != null &&
      !this.isTokenExpired(localStorage.getItem('token'))
    );
  }

  isTokenExpired(token: string): boolean {
    let currentDate = new Date();
    let decoded = jwt_decode(token) as any;
    let date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    if (this._global.user.token == '' && date >= currentDate) {
      this._global.user.token = token;
      this.api.get<Result<User>>('Account/getUser').subscribe((resp: any) => {
        let result: Result<User> = resp;
        if (result.result == true) {
          localStorage.setItem('token', result.response.token);
          this._global.user = result.response;

          this._global.userCache.next(result.response);
        }
      });
    }

    return date <= currentDate;
  }

  login(user: User): void {
    localStorage.setItem('token', user.token);
    this._global.user = user;
    this._global.userCache.next(user);
    this._router.navigate(['/starter']);
  }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): void {
    this.clear();

    this._router.navigate(['/login']);
  }

  decode() {
    return jwt_decode(localStorage.getItem('token'));
  }
}
