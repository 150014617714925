// export const environment = {
//   production: true,
//   clientUrl: 'https://sircula-app.co.za/welcome/',
//   apiBaseUrl: 'https://api.sircula-app.co.za/api', // without slash!!
//   apiBaseUrlv1: 'https://api.sircula-app.co.za/api', // without slash!!
// };
export const environment = {
  production: true,
  clientUrl: 'https://prod-sircula.avochoc.com/welcome/',
  apiBaseUrl: 'https://prod-sircula.avochoc.com/api', // without slash!!
  apiBaseUrlv1: 'https://prod-sircula.avochoc.com/api', // without slash!!
};
