import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalService {
  public user: User = {
    token: '',
    firstName: 'n/a',
    lastName: '',
    parentId: '',
    physicalAddress: '',
    profilePicture: '',
    isAdmin: false,
    id: '',
  };
  public userRoles: string = '';

  public AuthModels: any = {
    MasterAdmin: ['MasterAdmin'],
    Reseller: ['MasterAdmin', 'Reseller'],
    AppUser: ['MasterAdmin', 'Reseller', 'AppUser'],
  };

  public userCache: BehaviorSubject<User> = new BehaviorSubject<User>(
    this.user
  );
}
