// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  clientUrl: 'https://sircula-app.co.za/welcome/',
  apiBaseUrl: 'https://api.sircula-app.co.za/api', // without slash!!
  apiBaseUrlv1: 'https://api.sircula-app.co.za/api', // without slash!!
};

// export const environment = {
//   production: false,
//   clientUrl: 'https://localhost:4200/welcome/',
//   apiBaseUrl: 'http://localhost:5000/api', // without slash!!
//   apiBaseUrlv1: 'http://localhost:5000/api', // without slash!!
// };
